import * as React from 'react'
import { Link } from 'gatsby'

import './Hero.css'

import IconLess from '../../assets/icons/less.svg'
import IconGreater from '../../assets/icons/greater.svg'

const Hero = () => {
  return (
    <section id="about">
      <div className="hero">
        <h1>
          Hej, tu Marcin! <br /> Zajmuję się projektowaniem i tworzeniem
          skutecznych stron oraz sklepów internetowych, które sprzedają. Ponadto
          wspieram i pomagam w rozwoju już istniejących biznesów online.
        </h1>
      </div>

      <div className="slider">
        <p className="slider-title">Co mogę dla Ciebie zrobić?</p>

        <div className="slider-main">
          <figure className="slider-prev">
            <IconLess />
          </figure>
          <div className="slider-content">
            <div className="slider-item h3">
              <ol>
                <li>strony www</li>
                <li>sklep internetowy</li>
                <li>wsparcie biznesu online</li>
                <li>kawę *</li>
                <li>
                  <del>frytki</del>
                </li>
                <li>integracje</li>
                <li>automatyzacje</li>
                <li>projekty graficzne</li>
              </ol>
              <ol>
                <li>strony www</li>
                <li>sklep internetowy</li>
                <li>wsparcie biznesu online</li>
                <li>kawę *</li>
                <li>
                  <del>frytki</del>
                </li>
                <li>integracje</li>
                <li>automatyzacje</li>
                <li>projekty graficzne</li>
              </ol>
            </div>
          </div>
          <figure className="slider-next">
            <IconGreater />
          </figure>
        </div>
      </div>

      <div className="coffe">
        <p>
          * wpadnij na kawę i pogadajmy <br /> o Twoich pomysłach
        </p>
      </div>

      <div className="break-line"></div>

      <div className="brief">
        <p>
          lub skorzystaj z <br />
          formularza kontaktowego
        </p>
        <Link to="/brief" className="btn">
          Formularz
        </Link>
      </div>
    </section>
  )
}

export default Hero

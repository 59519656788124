import * as React from 'react'

import Seo from '../components/Seo/Seo'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero/Hero'
import Offer from '../components/Offer/Offer'
import Portfolio from '../components/Portfolio/Portfolio'
import Contact from '../components/Contact/Contact'

const IndexPage = () => {
  return (
    <>
      <Seo title="Zróbmy to!" />
      <Layout scrollSmooth={true}>
        <Hero />
        <Offer />
        <Portfolio />
        <Contact />
      </Layout>
    </>
  )
}

export default IndexPage

import * as React from 'react'
import { Link } from 'gatsby'

import './Contact.css'

const Contact = () => (
  <section id="contact">
    <div className="contact-brief">
      <h2 className="h4">Czekam na Twój brief!</h2>
      <p>
        Z chęcią przygotuję wycenę Twojego projektu za "freebie zip zero nada"!
        Wystarczy, że wypełnisz krótki formularz, który nie zajmie Ci więcej niż
        3 minuty. A kto wie, może to będzie klucz do osiągnięcia kolejnego celu
        biznesowego!
      </p>
      <div className="contact-go">
        <Link to="/brief" className="btn">
          Formularz
        </Link>
      </div>
    </div>
    <div className="contact-info">
      <ul className="contact-links">
        <li>
          <p className="label">Zadzwoń</p>
        </li>
        <li>
          <a href="tel:+48791406404" className="h3">
            +48 791 406 404
          </a>
        </li>
      </ul>
      <ul className="contact-links">
        <li>
          <p className="label">Napisz do mnie</p>
        </li>
        <li>
          <a href="mailto:do@webskigosc.pl" className="h3">
            do@webskigosc.pl
          </a>
        </li>
      </ul>
      <ul className="contact-links">
        <li>
          <p className="label">Wpadnij na kawę</p>
        </li>
        <li>
          <a href="https://goo.gl/maps/wGg1rrS1DJdWsrJ19" className="h3">
            Gdańska 91/93
          </a>
        </li>
        <li>
          <a href="https://goo.gl/maps/wGg1rrS1DJdWsrJ19" className="h3">
            90-613 Łódź
          </a>
        </li>
      </ul>
      <ul className="contact-links">
        <li>
          <p className="label">W zasięgu</p>
        </li>
        <li>
          <p className="h3">Poniedziałek - Piątek</p>
        </li>
        <li>
          <p className="h3">9:00 - 17:00</p>
        </li>
      </ul>
    </div>
  </section>
)

export default Contact

import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import LogoTH from '../../assets/clients/logo-testhartmana.svg'
import LogoSL from '../../assets/clients/logo-studiolodowa.svg'
import LogoCR from '../../assets/clients/logo-camperrent.svg'

import './Portfolio.css'

const Portfolio = () => {
  const clientsImg = useStaticQuery(graphql`
    query clientsImgQuery {
      camper: file(name: { eq: "bg-camperrent" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      studio: file(name: { eq: "bg-studiolodowa" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      test: file(name: { eq: "bg-testhartmana" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const realizations = [
    {
      title: 'Studio Lodowa',
      category: 'strona firmowa',
      description:
        'Autorski szablon dla WordPressa z możliwością rezerwacją studia online.',
      logo: <LogoSL />,
      link: 'https://studiolodowa.pl',
      image: getImage(clientsImg.studio),
    },
    {
      title: 'Test Hartmana',
      category: 'aplikacja webowa',
      description:
        'Test koloru osobowości w przyjemnej formie on-line oparty o badania T. Hartmana.',
      logo: <LogoTH />,
      link: 'https://testhartmana.pl',
      image: getImage(clientsImg.test),
    },
    {
      title: 'Camper Rent',
      category: 'strona firmowa',
      description:
        'Identyfikacja wizualna i autorski szablon dla WordPressa z możliwością rezerwacją terminów w kalendarzu.',
      logo: <LogoCR />,
      link: 'https://camperrent.pl',
      image: getImage(clientsImg.camper),
    },
  ]

  return (
    <section id="portfolio">
      <div className="portfolio-description">
        <h2 className="h4">Moje realizacje</h2>
        <p className="portfolio-text"></p>
      </div>
      {realizations.map((realization, index) => (
        <div key={index} className="card">
          <div className="card-client-logo">{realization.logo}</div>
          <GatsbyImage
            className="card-image"
            alt={realization.title + ' - ' + realization.category}
            image={realization.image}
          />
          <a
            href={realization.link}
            target="_blank"
            rel="noreferrer"
            className="card-body"
          >
            <span className="card-title h3">{realization.title}</span>
            <span className="card-category">{realization.category}</span>
            <p>{realization.description}</p>
          </a>
        </div>
      ))}
    </section>
  )
}

export default Portfolio

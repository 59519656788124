import * as React from 'react'

import './Offer.css'

import IconCode from '../../assets/icons/code.svg'
import IconBasket from '../../assets/icons/basket.svg'
import IconRocket from '../../assets/icons/rocket.svg'
import IconHelpHand from '../../assets/icons/helphand.svg'

const Offer = () => {
  const websites = [
    'platforma kursów online',
    'blog marki osobistej',
    'e-book landing page',
    'strona firmowa',
    '"prosta strona"',
    'wydarzenia, konferencji',
    'portfolio',
  ]
  const ecommerce = ['WooCommerce', 'PrestaShop', 'OpenCart']
  const integration = [
    'automatyzacja sprzedaży',
    'integracja z Allegro',
    'wdrożenie BaseLinkera',
    'wysyłanie newslettera',
    'zarządzanie spotkaniami',
    'generowanie grafik',
  ]
  const business = [
    'aktualizacja strony internetowej',
    'rozwój Twojego sklepu internetowego',
    'optymalizacja pod kątem SEO',
    'wdrożenie audytu UI/UX',
    'migracje między systemami CMS',
    'zmiana dostawcy usług, serwera, hostingu, domeny',
    'upraszczanie procesu sprzedaży',
  ]

  return (
    <section id="offer" className="bg-dark">
      <div className="offer-item">
        <div className="offer-title">
          <h2 className="h4">Strony www</h2>
        </div>
        <div className="offer-cover">
          <figure className="offer-icon">
            <IconCode />
          </figure>
          <div className="offer-text">
            <p>
              Projektuję strony internetowe od podstaw, dzięki czemu otrzymujesz
              autorski szablon, który nie ogranicza dalszego rozwoju strony jak
              i jej funkcjonalności.
            </p>
            <p>
              Dbając o dostępność i dostosowanie do urządzeń mobilnych w myśl
              "mobile first":
            </p>
            <ul>
              {websites.map((website, index) => (
                <li key={index}>{website}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="offer-item">
        <div className="offer-title">
          <h2 className="h4">Sklepy internetowe</h2>
        </div>
        <div className="offer-cover">
          <figure className="offer-icon">
            <IconBasket />
          </figure>
          <div className="offer-text">
            <p>
              Dobierając odpowiednie rozwiązania techniczne, tworzę przyjazne i
              zarabiające systemy sprzedaży E-commerce. Łatwe i przyjemne w
              administrowaniu, a przede wszystkim zwiększające sprzedaż.
            </p>
            <p>
              Starając się dostarczyć w relatywnie krótkim czasie odpowiednie
              narzędzia, dla małych i średnich biznesów proponuję:
            </p>
            <ul>
              {ecommerce.map((ecommerce, index) => (
                <li key={index}>{ecommerce}</li>
              ))}
            </ul>
            <p>
              Lecz nie jest to regułą, wszystko zależy od Twoich biznesowych
              założeń.
            </p>
          </div>
        </div>
      </div>
      <div className="offer-item">
        <div className="offer-title">
          <h2 className="h4">Integracje i automatyzacje</h2>
        </div>
        <div className="offer-cover">
          <figure className="offer-icon">
            <IconRocket />
          </figure>
          <div className="offer-text">
            <p>
              Jak to mówią "pracuj mądrze, a nie ciężko", czyli oszczędzaj czas
              dzięki automatyzacji:
            </p>
            <ul>
              {integration.map((integration, index) => (
                <li key={index}>{integration}</li>
              ))}
            </ul>
            <p>
              To tylko wierzchołek możliwości jakie dają zaawansowane narzędzia
              no-code i low-code, które w prosty sposób mogę wdrożyć do Twojej
              codziennej pracy.
            </p>
          </div>
        </div>
      </div>
      <div className="offer-item">
        <div className="offer-title">
          <h2 className="h4">Wsparcie Twojego biznesu</h2>
        </div>
        <div className="offer-cover">
          <figure className="offer-icon">
            <IconHelpHand />
          </figure>
          <div className="offer-text">
            <p>
              Masz stronę, a może sklep internetowy, ale brakuje Ci czasu? Jeśli
              potrzebujesz kogoś do pomocy przy bieżących zadaniach np.:
            </p>
            <ul>
              {business.map((business, index) => (
                <li key={index}>{business}</li>
              ))}
            </ul>
            <p>Śmiało pisz do mnie, dogadamy szczegóły.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Offer
